import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'

//IMPORT TARGET TOKO
export const importTargetCategory = createAsyncThunk(
  'customer-target-category/import',
  async ({ formdata }) => {
    const response = await axios
      .post(`${URL_API}/v1/api/target_category/`, formdata, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .catch((err) => {
        console.log(err, 'error add')
      })

    return response.data.data
  },
)

//TARGET CATEGORY ALL
export const getTargetCategoryAll = createAsyncThunk(
  'customers-target-category/all',
  async (parameter) => {
    console.log('API target kategori hit')
    const params = {
      by: 'def.id',
      customer_code: parameter.customer_code,
      name: parameter.name,
    }

    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    const response = await axios.get(`${URL_API}/v1/api/target_category/select?`, config)
    //console.log('resp data: ', response.data.data)
    return response.data.data
  },
)

//TARGET CATEGORY DISTICT
export const getTargetCategoryAllDistict = createAsyncThunk(
  'customers-target-category/all/distict',
  async (parameter) => {
    console.log('API target kategori hit')
    const params = {
      by: 'def.id',
      customer_code: parameter.customer_code,
      sort: 'desc',
    }

    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    const response = await axios.get(`${URL_API}/v1/api/target_category/select/distinct?`, config)
    //console.log('resp data: ', response.data.data)
    return response.data.data
  },
)

//TARGET CATEGORY BY ID
export const getTargetCategoryByID = createAsyncThunk(
  'customers-target-category/id',
  async (params) => {
    console.log('API target katgori mysm hit')
    const response = await axios.get(`${URL_API}/v1/api/target_category/id/` + params.customer_id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    return response.data.data == null ? [] : response.data.data
  },
)

//REPORT TARGET CATEGORY ALL
export const getReportTargetCategoryAll = createAsyncThunk(
  'customers-target-category/report',
  async (parameter) => {
    console.log('API target kategori hit')
    const params = {
      customer_code: parameter.customer_code,
      sort: 'desc',
      region_group_id: parameter.region_group_id,
      region_id: parameter.region_id,
      branch_id: parameter.branch_id,
      names: parameter.names,
      start_date: parameter.start_date,
      end_date: parameter.end_date,
    }

    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    // const response = await axios.get(`${URL_API}/v1/api/target_category/report?`, config)
    // // console.log('resp data: ', response.data.data)
    // return response.data.data

    try {
      const response = await axios.get(`${URL_API}/v1/api/target_category/report?`, config)

      // Check if data is null
      if (!response.data || response.data.data === null || response.data.data.length === 0) {
        window.alert('Data tidak ditemukan.')
        return null // Return null to indicate no data
      }

      // Return data if it exists
      return response.data.data
    } catch (error) {
      console.error('Error fetching data:', error)
      window.alert('Terjadi kesalahan saat mengambil data.')
      return null // Return null in case of error
    }
  },
)

const targetCategoryEntity = createEntityAdapter({
  selectId: (targetCategory) => targetCategory.id,
})

const targetCategorySlice = createSlice({
  name: 'targetCategory',
  initialState: targetCategoryEntity.getInitialState(),
  extraReducers: {
    [importTargetCategory.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        targetCategoryEntity.addOne(state, action.payload)
      }
    },

    [getTargetCategoryAll.fulfilled]: (state, action) => {
      targetCategoryEntity.setAll(state, action.payload)
    },
    [getTargetCategoryAllDistict.fulfilled]: (state, action) => {
      targetCategoryEntity.setAll(state, action.payload)
    },
    [getTargetCategoryByID.fulfilled]: (state, action) => {
      targetCategoryEntity.setAll(state, action.payload)
    },
    [getReportTargetCategoryAll.fulfilled]: (state, action) => {
      targetCategoryEntity.setAll(state, action.payload)
    },
  },
})
export const targetCategorySelector = targetCategoryEntity.getSelectors(
  (state) => state.targetCategory,
)
export const selectTargetCategory = (state) => state.targetCategory
export default targetCategorySlice.reducer
