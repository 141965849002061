import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

// LAST SEEN ACTIVITY
export const getLastSeen = createAsyncThunk('activity-user/last-seen', async (parameter) => {
  console.log('API getLastSeen hit')
  const params = {
    by: 'def.id',
    customer_id: parameter?.customer_id || '',
    sort: 'desc',
  }

  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/customer_activities/select?`, config)

  return response.data.data
})

// LAST SEEN ACTIVITY REPORT
export const getLastSeenReport = createAsyncThunk(
  'activity-user/last-seen-report',
  async (parameter) => {
    console.log('API getLastSeen hit')
    const params = {
      by: 'def.id',
      sort: 'desc',
      page: parameter?.page || 1,
      limit: parameter?.limit || 10,
    }

    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    const response = await axios.get(`${URL_API}/v1/api/customer_activities/?`, config)
    const responseData = {
      data: response.data.data == null ? [] : response.data.data,
      meta: response.data.meta == null ? [] : response.data.meta,
    }

    return responseData
  },
)

// LAST SEEN ACTIVITY REPORT SELECT ALL
export const getLastSeenReportSelect = createAsyncThunk(
  'activity-user/last-seen-report-all',
  async () => {
    console.log('API getLastSeen hit report')
    const params = {
      by: 'def.id',
      sort: 'desc',
    }

    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    const response = await axios.get(`${URL_API}/v1/api/customer_activities/select?`, config)

    return response.data.data
  },
)

const activityUserEntity = createEntityAdapter({
  selectId: (activityUser) => activityUser.customer_id,
})

const activityUserSlice = createSlice({
  name: 'activityUser',
  initialState: {
    ...activityUserEntity.getInitialState(),
    loading: false, // Tambahkan loading state
  },
  extraReducers: {
    [getLastSeen.pending]: (state) => {
      state.loading = true
    },
    [getLastSeen.fulfilled]: (state, action) => {
      state.loading = false
      activityUserEntity.setAll(state, action.payload)
    },
    [getLastSeen.rejected]: (state) => {
      state.loading = false
    },

    [getLastSeenReport.pending]: (state) => {
      state.loading = true
    },
    [getLastSeenReport.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload.data
      state.meta = action.payload.meta
      activityUserEntity.setAll(state, action.payload)
    },
    [getLastSeenReport.rejected]: (state) => {
      state.loading = false
    },

    [getLastSeenReportSelect.pending]: (state) => {
      state.loading = true
    },
    [getLastSeenReportSelect.fulfilled]: (state, action) => {
      state.loading = false
      activityUserEntity.setAll(state, action.payload)
    },
    [getLastSeenReportSelect.rejected]: (state) => {
      state.loading = false
    },
  },
})

export const activityUserSelector = activityUserEntity.getSelectors((state) => state.activityUser)
export const selectActivityUser = (state) => state.activityUser
export default activityUserSlice.reducer

// import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
// import axios from 'axios'
// import { URL_API } from '../../config/enviroment'

// //LAST SEEN ACTIVITY
// export const getLastSeen = createAsyncThunk('activity-user/last-seen', async (parameter) => {
//   console.log('API getLastSeen hit')
//   const params = {
//     by: 'def.id',
//     customer_id: parameter?.customer_id || '',
//     sort: 'desc',
//   }

//   const config = {
//     params: params,
//     headers: {
//       Authorization: 'Bearer ' + localStorage.getItem('token'),
//     },
//   }
//   const response = await axios.get(`${URL_API}/v1/api/customer_activities/select?`, config)

//   return response.data.data
// })

// //LAST SEEN ACTIVITY REPORT
// export const getLastSeenReport = createAsyncThunk('activity-user/last-seen', async (parameter) => {
//   const params = {
//     by: 'def.id',
//     customer_id: parameter?.customer_id || '',
//     sort: 'desc',
//   }

//   const config = {
//     params: params,
//     headers: {
//       Authorization: 'Bearer ' + localStorage.getItem('token'),
//     },
//   }
//   const response = await axios.get(`${URL_API}/v1/api/customer_activities/select?`, config)
//   const responseData = {
//     data: response.data.data == null ? [] : response.data.data,
//     meta: response.data.meta == null ? [] : response.data.meta,
//   }

//   return responseData
// })

// //LAST SEEN ACTIVITY REPORT SELECT ALL
// //LAST SEEN ACTIVITY
// export const getLastSeenReportSelect = createAsyncThunk(
//   'activity-user/last-seen-report',
//   async (parameter) => {
//     console.log('API getLastSeen hit')
//     const params = {
//       by: 'def.id',
//       sort: 'desc',
//     }

//     const config = {
//       params: params,
//       headers: {
//         Authorization: 'Bearer ' + localStorage.getItem('token'),
//       },
//     }
//     const response = await axios.get(`${URL_API}/v1/api/customer_activities/select?`, config)

//     return response.data.data
//   },
// )

// const activityUserEntity = createEntityAdapter({
//   selectId: (activityUser) => activityUser.customer_id,
// })

// const activityUserSlice = createSlice({
//   name: 'activityUser',
//   initialState: activityUserEntity.getInitialState(),
//   extraReducers: {
//     [getLastSeen.fulfilled]: (state, action) => {
//       activityUserEntity.setAll(state, action.payload)
//     },
//     [getLastSeenReport.fulfilled]: (state, action) => {
//       state.data = action.payload.data
//       state.meta = action.payload.meta
//       activityUserEntity.setAll(state, action.payload)
//     },
//     [getLastSeenReportSelect.fulfilled]: (state, action) => {
//       activityUserEntity.setAll(state, action.payload)
//     },
//   },
// })

// export const activityUserSelector = activityUserEntity.getSelectors((state) => state.activityUser)
// export const selectActivityUser = (state) => state.activityUser
// export default activityUserSlice.reducer
